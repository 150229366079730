<template>
  <NotificationBase
    :notification
    :icon
    :compact
    link="/personal/bets?sportTab=processed"
  >
    <template #subject>
      {{ subject }}
    </template>
    <i18n-t
      v-if="notification.type === 'betUnacceptedOrdinary'"
      keypath="notifications.body.betUnacceptedOrdinary"
      tag="p"
    >
      <template #eventName>
        <span>{{ eventName }}</span>
      </template>
      <template #amount>
        <b>{{ notificationAmount }}</b>
      </template>
    </i18n-t>
    <i18n-t
      v-else
      keypath="notifications.body.betProcessedWinLosOrdinary"
      tag="p"
    >
      <template #eventName>
        <span>{{ eventName }}</span>
      </template>
      <template #amount>
        <b>{{ notificationAmount }}</b>
      </template>
    </i18n-t>
  </NotificationBase>
</template>

<script setup lang="ts">
import { parseLanguageMap } from '@st/utils'
import NotificationBase from '../NotificationBase.vue'
import type { BetOrdinaryNotification } from '../../../../types'

const { notification, compact = false } = defineProps<{
  notification: BetOrdinaryNotification
  compact?: boolean
}>()

const { notificationAmount, currencyIcon } = useCurrencyNotification(
  toRef(() => notification),
)

const icon = computed(() => ({
  name: 'cup-solid' as const,
  currencyIcon: currencyIcon.value,
}))

const { t, locale } = useI18n()
const eventName = computed(() =>
  parseLanguageMap(notification.data.eventName ?? '', locale.value),
)

const subject = computed(
  () =>
    ({
      betProcessedWinOrdinary: t(
        'notifications.subject.betProcessedWinOrdinary',
      ),
      betProcessedLossOrdinary: t(
        'notifications.subject.betProcessedLossOrdinary',
      ),
      betUnacceptedOrdinary: t('notifications.subject.betUnacceptedOrdinary'),
    })[notification.type],
)
</script>
